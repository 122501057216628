<template>
  <b-card no-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="details"
      striped
    >
      <template #cell(company)="data">
        <div class="d-flex align-content-start align-items-center">
          <div>
            <b-button
              v-b-tooltip.hover.top="'Katalog Görüntüle'"
              variant="flat-success"
              class="btn-icon rounded-circle"
              :href="'/app/catalogs/view/' + data.item.id"
              target="_blank"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div class="ml-1">
            <div>
              {{ data.item.company }}
            </div>
            <div class="font-small-2 text-primary">
              {{ data.item.title }}
            </div>
            <div class="font-small-2 font-italic text-muted">
              <template v-if="data.item.modified">
                {{ moment(data.item.modified).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} tarafından güncellendi.
              </template>
              <template v-else-if="data.item.created">
                {{ moment(data.item.created).format('DD.MM.YYYY') }} tarihinde {{ data.item.username }} tarafından oluşturuldu.
              </template>
            </div>
          </div>
        </div>
      </template>
      <template #cell(expiry_date)="data">
        <div>
          {{ moment(data.item.expiry_date).format('DD.MM.YYYY') }}
        </div>
        <div class="font-small-2 text-warning">
          <span v-if="moment(data.item.expiry_date).diff(moment(),'days') > 0">
            {{ moment(data.item.expiry_date).diff(moment(),'days') }} gün kaldı.
          </span>
          <span
            v-else
            class="text-danger"
          >
            Süre doldu.
          </span>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <div class="d-flex justify-content-between align-items-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardFooter, BButton, BTable, BPagination, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'Details',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'company',
          label: 'FİRMA ADI',
        },
        {
          key: 'catalog_status',
          label: 'DURUM',
          thClass: 'text-nowrap text-center width-200',
          tdClass: 'text-nowrap text-center width-200',
        },
        {
          key: 'expiry_date',
          label: 'GEÇERLİLİK TARİHİ',
          thClass: 'text-nowrap width-200',
        },
      ],
    }
  },
  computed: {
    filterParams() {
      return this.$store.getters['catalogReports/filterParams']
    },
    details() {
      return this.$store.getters['catalogReports/details']
    },
    dataCount() {
      return this.$store.getters['catalogReports/dataCount']
    },
  },
  watch: {
    currentPage(val) {
      this.filterParams.start = (val - 1) * this.perPage
      this.getReport()
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('catalogReports/getDetail', this.filterParams)
    },
  },
}
</script>

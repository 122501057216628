<template>
  <div>
    <b-card title="Beğenilenler">
      <b-row v-if="likes.length > 0">
        <b-col
          v-for="item in likes"
          :key="item.id"
          cols="12"
          md="3"
        >
          <div class="border p-1 text-center rounded mb-3">
            <b-img
              v-if="item.image"
              :src="baseUrl + '/media/products/' + item.image"
              fluid
            />
            <empty-image v-else />
            <div class="font-weight-bolder mt-1">
              {{ item.pcode }}
            </div>
            <div
              class="text-success d-flex align-items-center justify-content-center font-weight-bolder"
            >
              <FeatherIcon
                icon="ThumbsUpIcon"
                class="mr-1"
              /> {{ item.totals }}
            </div>
          </div>
        </b-col>
      </b-row>
      <empty-alert v-else />
    </b-card>
    <b-card title="Beğenilmeyenler">
      <b-row v-if="dislikes.length > 0">
        <b-col
          v-for="item in dislikes"
          :key="item.id"
          cols="12"
          md="3"
        >
          <div class="border p-1 text-center rounded mb-3">
            <b-img
              v-if="item.image"
              :src="baseUrl + '/media/products/' + item.image"
              fluid
            />
            <empty-image v-else />
            <div class="font-weight-bolder mt-1">
              {{ item.pcode }}
            </div>
            <div
              class="text-danger d-flex align-items-center justify-content-center font-weight-bolder"
            >
              <FeatherIcon
                icon="ThumbsDownIcon"
                class="mr-1"
              /> {{ item.totals }}
            </div>
          </div>
        </b-col>
      </b-row>
      <empty-alert v-else />
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BImg, BBadge,
} from 'bootstrap-vue'
import EmptyAlert from '@/views/Admin/Reports/common/EmptyAlert.vue'
import EmptyImage from '@/layouts/components/common/EmptyImage.vue'

export default {
  name: 'Products',
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BBadge,
    EmptyAlert,
    EmptyImage,
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
    }
  },
  computed: {
    likes() {
      return this.$store.getters['catalogReports/likes']
    },
    dislikes() {
      return this.$store.getters['catalogReports/dislikes']
    },
  },
}
</script>

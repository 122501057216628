<template>
  <b-card>
    <vue-apex-charts
      v-if="chartData.series.length > 0"
      type="donut"
      :height="heightSize"
      width="100%"
      :options="chartData.chartOptions"
      :series="chartData.series"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    VueApexCharts,
    BCard,
  },
  props: {
    heightSize: {
      // eslint-disable-next-line no-bitwise,vue/require-prop-type-constructor
      type: (String | Number),
      default: 450,
    },
    seriesData: {
      type: Array,
      required: true,
    },
    categoriesData: {
      type: Array,
      required: true,
    },
    pieLabels: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      chartData: {
        series: this.seriesData,
        chartOptions: {
          legend: {
            show: true,
            position: 'bottom',
            fontSize: '14px',
            fontFamily: 'Montserrat',
          },
          tooltip: {
            enabled: true,
            y: {
              show: true,
              format: 'currency',
              formatter(val) {
                const currency = new Intl.NumberFormat('tr-TR', {
                  // style: "currency",
                  // currency: "EUR",
                  minimumFractionDigits: 2,
                })
                return currency.format(val)
              },
            },
          },
          dataLabels: {
            enabled: true,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}%`
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: this.pieLabels,
                  name: {
                    fontSize: '1rem',
                    fontFamily: 'Montserrat',
                  },
                  value: {
                    formatter(val) {
                      // eslint-disable-next-line radix
                      const value = parseInt(val)
                      return `${value} Adet`
                    },
                  },
                  total: {
                    show: false,
                  },
                },
              },
            },
          },
          labels: this.categoriesData,
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: 'bottom',
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: this.pieLabels,
                        name: {
                          fontSize: '1rem',
                        },
                        value: {
                          fontSize: '1rem',
                        },
                        total: {
                          fontSize: '1.5rem',
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    }
  },
  watch: {
    seriesData: {
      deep: true,
      handler(val) {
        this.chartData.series = val
      },
    },
    categoriesData: {
      deep: true,
      handler(val) {
        this.chartData.chartOptions.labels = val
        this.chartData.chartOptions = {
          ...this.chartData.chartOptions,
        }
      },
    },
  },
  mounted() {
    this.setData()
  },
  methods: {
    setData() {
      this.chartData.series = this.seriesData
      this.chartData.chartOptions.labels = this.categoriesData
      this.chartData.chartOptions = {
        ...this.chartData.chartOptions,
      }
    },
  },
}
</script>
